import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import { createStore } from 'vuex'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const store = createStore({
    state () {
        return {
            email: ''
        }
    },
    mutations: {
        setEmail (state, email) {
            state.email = email;
        }
    }
})


createApp(App).use(router).use(store).mount('#app')

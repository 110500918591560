<template>
  <div class="dropdown">
    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
      {{ email }}
    </button>
    <ul class="dropdown-menu w-100">
      <li class="dropdown-item" @click="logout">
        Log out
      </li>
    </ul>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {AttiniApi} from "@/AttiniApi";

export default {
  name: "Logout",
  methods: {
    getEmail() {
      AttiniApi.get( "email", {}).then(value => {
        this.email = value.data;
        this.updateEmail(value.data);
      }).catch(reason => console.log(reason))
    },
    async logout() {
      this.$emit('loggedOut', true);
    }
  },

  setup(){
    const store = useStore();

    function updateEmail(email){
      store.commit('setEmail', email);
    }
    return {updateEmail}
  },
  created() {
    this.getEmail();
  },
  data() {
    return {
      email: ''
    }
  },
}
</script>

<style scoped>

</style>
import {createRouter, createWebHistory} from 'vue-router'
import {AttiniAuth} from "@/AttiniAuth";

const routes = [
    {
        path: '/',
        name: 'Landing',
        meta: {requiresAuth: true},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BillingInformation.vue')
    },
    {
        path: '/billing',
        name: 'Billing',
        meta: {requiresAuth: true},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BillingInformation.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import(/* webpackChunkName: "register" */ '../views/register/Register.vue'),
    },
    {
        path: '/create-account-group',
        name: 'Account group',
        meta: {requiresAuth: true},
        component: () =>
            import(/* webpackChunkName: "createOrg" */ '../views/account-group/CreateAccountGroup.vue'),
    },
    {
        path: '/account-groups',
        name: 'Account groups',
        meta: {requiresAuth: true},
        component: () =>
            import(/* webpackChunkName: "createOrg" */ '../views/account-group/AccountGroups.vue'),
    },
    {
        path: '/create-organization',
        name: 'Create Organization',
        meta: {requiresAuth: true},
        component: () =>
            import(/* webpackChunkName: "createOrg" */ '../views/register/CreateOrganization.vue'),
    },
    {
        path: '/manage-organization',
        name: 'Manages Organization',
        meta: {requiresAuth: true},
        component: () =>
            import(/* webpackChunkName: "manageOrg" */ '../views/ManageOrganization.vue'),
    },
    {
        path: '/page-not-found',
        name: 'Page not found',
        component: () =>
            import(/* webpackChunkName: "manageOrg" */ '../views/PageNotFound.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (!router.hasRoute(to.name)){
        next({
            path: '/page-not-found'
        });
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        AttiniAuth.getSession().then(() => {
            next()
        }).catch(() => {
            next({
                path: '/login'
            });
        });
    }
    else if(to.name === "Login" || to.name === 'Register'){
        AttiniAuth.getSession().then(() => {
            next('/')
        }).catch(() => {
            //do nothing
            next()
        });
    }
    else{
        next();
    }
})

export default router

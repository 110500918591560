import {CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails} from 'amazon-cognito-identity-js'
import axios from "axios";

class AttiniAuthClass {

    userPool = null;

    constructor() {

        if (localStorage.getItem("attini-user-pool-id") == null || localStorage.getItem("attini-client-id") == null) {
            this.reloadUserPool();
        } else {
            this.userPool = new CognitoUserPool({
                UserPoolId: localStorage.getItem("attini-user-pool-id"),
                ClientId: localStorage.getItem("attini-client-id")
            });
        }

    }

    reloadUserPool() {
        axios.get("rest/open/userpool")
            .then(value => {
                localStorage.setItem("attini-user-pool-id", value.data.userPoolId);
                localStorage.setItem("attini-client-id", value.data.userPoolClientId);
                this.userPool = new CognitoUserPool({
                    UserPoolId: value.data.userPoolId,
                    ClientId: value.data.userPoolClientId
                });
            });
    }


    getUser() {
        return this.userPool.getCurrentUser();
    }

    confirmPassword(username, code, newPassword) {
        let userData = {Username: username, Pool: this.userPool}

        let cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(code, newPassword,  {
                onSuccess: () => resolve(),
                onFailure: err => reject(err)
            });
        })
    }

    confirmSignUp(username, code) {
        let userData = {Username: username, Pool: this.userPool}

        let cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, false, (err, result) => {
                if (err) {
                    reject(err);
                }
                resolve(result);
            });
        })
    }

    changeTempPassword(user, newPassword) {
        return new Promise((resolve, reject) => {
            user.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: session => {
                    resolve(session);
                },
                onFailure: err => {
                    reject(err);
                }
            })
        })
    }

    signUp(username, password) {
        let attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: username
            })
        ];
        return new Promise((resolve, reject) => {
            this.userPool.signUp(username, password, attributeList, null, (err, result) => {
                if (err) {
                    reject(err);
                }
                resolve(result);
            });
        })
    }

    login(username, password) {
        let authenticationData = {Username: username, Password: password}
        let authDetails = new AuthenticationDetails(authenticationData);
        let userData = {Username: username, Pool: this.userPool}

        let cognitoUser = new CognitoUser(userData);

        return new Promise(((resolve, reject) => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: result => {
                    resolve(result);
                },
                onFailure: err => {
                    reject(err);
                },
                newPasswordRequired: (userAttributes) => {
                    resolve({"newPasswordNeeded": true, "userAttributes": userAttributes, cognitoUser: cognitoUser});
                },


            })
        }))

    }

    getToken() {
        return this.getSession().then(value => value.getIdToken().getJwtToken());
    }

    logout() {
        this.getUser().signOut();
    }

    resetPassword(email) {
        let userData = {Username: email, Pool: this.userPool}

        let cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                    onSuccess: data => resolve(data),
                    onFailure: err => reject(err)
                }
            )
        })

    }

    getSession() {
        return new Promise((resolve, reject) => {
            let user = this.getUser();
            if (user != null) {
                user.getSession((error, session) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(session)
                    }
                })
            } else {
                reject("no logged in user");
            }
        })
    }
}

export const AttiniAuth = new AttiniAuthClass();
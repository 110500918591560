<template>
    <div class="container">
      <div class="container-fluid">
        <logout v-if="loggedIn" @loggedOut="logout" class="position-absolute top-0 end-0"></logout>
      </div>
      <div>
        <router-link to="/">
          <img alt="Attini logo" src="./assets/AttiniLogo.png" class="logo">
        </router-link>
      </div>

      <nav class="navbar navbar-expand-lg navbar-light" style="padding-bottom: 80px">
        <div class="container-fluid">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <router-link class="nav-link" v-if="loggedIn" to="/billing">Billing</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" v-if="loggedIn" to="/create-account-group">Create account group
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" v-if="loggedIn" to="/account-groups">Account groups</router-link>
            </li>

            <li class="nav-item" v-if="loggedIn">
              <router-link to="/manage-organization" class="nav-link">Manage Organization</router-link>
            </li>

          </ul>
        </div>
      </nav>
    </div>
  <div style="max-width: 620px; margin: 0 auto;">
    <router-view/>
  </div>
</template>

<script>
import Logout from "@/components/Logout";
import {AttiniAuth} from "@/AttiniAuth";

export default {
  components: {Logout},
  data() {
    return {
      loggedIn: false,
      email: ''
    }
  },
  created() {
    this.isLoggedIn();
  },
  watch: {
    '$route'() {
      this.isLoggedIn();
    }
  },
  methods: {
    async isLoggedIn() {
      this.loggedIn = await AttiniAuth.getSession().then(() => true).catch(() => false);
    },
    async logout() {
      try {
        await AttiniAuth.logout();
        this.loggedIn = false;
        if (this.$route.meta.requiresAuth) {
          await this.$router.push("/login");
        }
      } catch (error) {
        await this.$router.push("/login");
        console.log(error.message);
      }
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.logo {
  max-width: 300px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.errorMessage{
  color: #dc3545;
}

.button {
  background-color: #006A6D;
  color: white;
  border: none;
  display: inline-block;
  font-size: 16px;
  padding: 12px 32px;
  border-radius: 9999px;
  cursor: pointer;
  margin-top: 20px;
}

p {
  max-width: 80%;
  margin: 1rem auto;
}

input {
  margin-top: 15px;
  padding: 12px 5px;
  width: 300px;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid grey;
}


</style>

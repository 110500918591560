import {AttiniAuth} from "@/AttiniAuth";
import axios from "axios";

class AttiniApiClass {

     apiPrefix = "/rest/";


    get(path) {
        return AttiniAuth.getToken()
            .then(token => axios.get(this.apiPrefix +path, {headers: {'Authorization': token}}));
    }

    post(path, data) {
        return AttiniAuth.getToken()
            .then(token => axios.post(this.apiPrefix +path, data ,{headers: {'Authorization': token}}));
    }

    delete(path, data) {
        return AttiniAuth.getToken()
            .then(token => axios.delete(this.apiPrefix +path,{headers: {'Authorization': token}, data: data}));
    }

    put(path, data) {
        return AttiniAuth.getToken()
            .then(token => axios.put(this.apiPrefix + path, data ,{headers: {'Authorization': token}}));
    }
}

export const AttiniApi = new AttiniApiClass();